import { useFrame } from "@react-three/fiber"
import React from 'react'
import { MeshBasicMaterial, Vector3 } from "three"
import MainText from "./Text"

import { OpacityContext } from "./OpacityValueInterpolation"

function Box(props: JSX.IntrinsicElements['mesh']) {
    // This reference will give us direct access to the THREE.Mesh object
    const ref = React.useRef<THREE.Mesh>(null!)
    // Hold state for hovered and clicked events
    const [hovered, setHover] = React.useState(false)
    const [clicked, setClick] = React.useState(false)
    // Rotate mesh every frame, this is outside of React without overhead
    useFrame((state, delta) => (ref.current.rotation.x += 0.01))

    return (
        <mesh
            {...props}
            ref={ref}
            scale={clicked ? 1.5 : 1}
            onClick={(event) => setClick(!clicked)}
            onPointerOver={(event) => setHover(true)}
            onPointerOut={(event) => setHover(false)}>

            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
        </mesh>
    )
}

type MeshProps = JSX.IntrinsicElements['mesh']

interface TextBoxProps extends MeshProps {
    text: string
    opacity?: number
}

export default function TextBox(props: TextBoxProps) {
    const ref = React.useRef<THREE.Mesh>(null!)

    useFrame((state, delta) => { ref.current.rotation.z += 0.8 * delta; })
    let ctxOpacity = React.useContext(OpacityContext)
    let opacity = props.opacity !== undefined ? props.opacity : 1;

    if(ctxOpacity != -1)
        opacity = ctxOpacity;
    return (
        <mesh {...props}>
            <MainText opacity={opacity} text={props.text} position={[0, 0, 0.6]} fontSize={0.3} />
            <mesh ref={ref}>
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'orange'} transparent={true} opacity={opacity} />
            </mesh>
        </mesh>
    )
}