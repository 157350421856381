import React, { useMemo } from "react"
import * as THREE from 'three'
import { AppState, GetStateTransformation, OnAppStateChange } from "./AppState"

import TextBox from './utils/TextBox'
import MainText from "./utils/Text"
import { OpacityValueInterpolation, FadeDirection, SelectFadeDirection } from "./utils/OpacityValueInterpolation"

import { MainPageCameraPosition } from "./App"
import { VzdelaniCameraPosition } from "./pages/Vzdelani"
import { KonickyCameraPosition } from "./pages/Konicky"

// Center X position of this view.
export const KdoJsemPositionX: number = -4.1;
export const KdoJsemPositionY: number = 0;

export const KdoJsemCameraPosition: THREE.Vector3 = new THREE.Vector3(KdoJsemPositionX, KdoJsemPositionY, 5);

const ThisAppState: AppState = AppState.KdoJsem;

export function KdoJsem(onAppStateChange: OnAppStateChange) {

    function OnClick(toPosition: THREE.Vector3, appState: AppState) {
        onAppStateChange.onAppStateChange(toPosition, appState)
    }

    return (
        <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(ThisAppState, onAppStateChange.appStates))} >
            <MainText text="Programátor" fontSize={0.3} position={[KdoJsemPositionX, 2.5, 0]} />
            <MainText text="Takovej normální člověk" fontSize={0.3} position={[KdoJsemPositionX, 1.5, 0]} />
            <TextBox position={[KdoJsemPositionX - 1.1, 0, 0]} text='Vzdělání' onClick={() => OnClick(VzdelaniCameraPosition, AppState.Vzdelani)} />
            <TextBox position={[KdoJsemPositionX, -1.1, 0]} text='Koníčky' onClick={() => OnClick(KonickyCameraPosition, AppState.Konicky)} />
            <TextBox position={[KdoJsemPositionX + 1.1, 0, 0]} text='Zpět' onClick={() => OnClick(MainPageCameraPosition, AppState.MainPage)} />
        </OpacityValueInterpolation >
    )
}


