import React from "react"
import * as THREE from 'three'
import { AppState, GetStateTransformation, OnAppStateChange } from "./../AppState"

import TextBox from './../utils/TextBox'
import MainText from "./../utils/Text"
import { KdoJsemCameraPosition } from "../KdoJsem"
import { OpacityValueInterpolation, SelectFadeDirection } from "../utils/OpacityValueInterpolation"

export const VzdelaniPositionX : number = -4.1 * 2;
export const VzdelaniPositionY : number = 0;

export let VzdelaniCameraPosition : THREE.Vector3 = new THREE.Vector3(VzdelaniPositionX, VzdelaniPositionY, 5);

const ThisAppState: AppState = AppState.Vzdelani;

export function Vzdelani(onAppStateChange : OnAppStateChange)
{
    return (
        <>
        <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(ThisAppState, onAppStateChange.appStates))} >
            <MainText text="SPŠ Na Třebešíně\n\nFakulta Informačních\nTechnologií\nČVUT\nObor\nPočítačová Grafika" fontSize={0.3} position={[VzdelaniPositionX, 0, 0]} />
            <MainText text="Bakalářská práce\nna téma\nOptimalizace Výpočtu\nExplozí v VBS4" fontSize={0.2} position={[VzdelaniPositionX - 0.7, -2, 0]} />
            <TextBox position={[VzdelaniPositionX + 1.1, 0, 0]} text='Zpět' onClick={() => onAppStateChange.onAppStateChange(KdoJsemCameraPosition, AppState.KdoJsem)} />
            <TextBox position={[VzdelaniPositionX, VzdelaniPositionY - 2, 0]} text='Bc. Link' onClick={() => window.open("https://dspace.cvut.cz/handle/10467/90354")}/>
        </OpacityValueInterpolation>
        </>
    )
}