import React from 'react'
import { Text } from '@react-three/drei';
import { Vector3 } from '@react-three/fiber';
import { OpacityContext } from './OpacityValueInterpolation';

export default function MainText({ text, fontSize = 1, position = [0, 2.5, 0], opacity = 1 }: { text: String, fontSize?: number, position?: Vector3, opacity?: number }) {
    if (!(position instanceof Array))
        return <></>

    let ctxOpacity = React.useContext(OpacityContext)
    if (ctxOpacity != -1)
        opacity = ctxOpacity;


    if (ctxOpacity != -1)
        opacity = ctxOpacity;

    const textArr: Array<String> = text.split('\\n');
    return (
        <>
            {textArr.map((item: String, index: number) => {
                return <Text position={[position[0], position[1] - ((fontSize * index) - Math.floor(textArr.length / 2)), position[2]]} fontSize={fontSize} color="black"
                    key={index} fillOpacity={opacity} >{item}</Text>
            })
            }
        </>
    )

}
