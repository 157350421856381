import TWEEN from "@tweenjs/tween.js";
import React, { Children, useEffect } from "react";
import { AppState, AppStates, StateTransformation } from "../AppState";

const InterpolationTime = 200;

export enum FadeDirection {
    In,
    Out
}

export function SelectFadeDirection(stateTransformation : StateTransformation)
{
    switch(stateTransformation)
    {
        case StateTransformation.In:
            return FadeDirection.In;
        case StateTransformation.Out:
            return FadeDirection.Out;
        default:
            return FadeDirection.In;
    }
}

interface OpacityValueInterpolationInfo {
    fadeDirection: FadeDirection,
    children?: React.ReactNode
}


export const OpacityContext = React.createContext(-1);

export function OpacityValueInterpolation(props: OpacityValueInterpolationInfo) {
    let [opacity, setOpacity] = React.useState(0);

    React.useMemo(() => {
        let start = 0;
        let end = 1;
        if (props.fadeDirection == FadeDirection.In) {
        }
        if (props.fadeDirection == FadeDirection.Out) {
            let tmp = end;
            end = start;
            start = tmp;
        }
        new TWEEN.Tween({ x: start }).to({ x: end }, InterpolationTime).easing(TWEEN.Easing.Linear.InOut)
            .onUpdate((object) => {
                setOpacity(object.x)
            }
            ).onComplete((object) => { }).start()
    }, [props.fadeDirection])

    return (<>
        <OpacityContext.Provider value={opacity}>
            {props.children}
        </OpacityContext.Provider>
    </>)
}