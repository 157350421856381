import React from "react"
import * as THREE from 'three'
import { AppState, GetStateTransformation, OnAppStateChange } from "./../AppState"

import TextBox from './../utils/TextBox'
import MainText from "./../utils/Text"

import { ProjektyCameraPosition } from "../Projekty"
import { OpacityValueInterpolation, SelectFadeDirection } from "../utils/OpacityValueInterpolation"
import { KdoJsemCameraPosition } from "../KdoJsem"

// Center X position of this view.
export const KonickyPositionX: number = -4.1;
export const KonickyPositionY: number = -7.0;

export const KonickyCameraPosition: THREE.Vector3 = new THREE.Vector3( KonickyPositionX, KonickyPositionY, 5);

const ThisAppState: AppState = AppState.Konicky;

export function Konicky(onAppStateChange: OnAppStateChange) {
    function OnClickLink() {
        //window.open("https://dotaznik.radovannetik.cz")
    }

    return (
        <>
            <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(ThisAppState, onAppStateChange.appStates))} >
                <MainText text="Fitness (not now)\nRopes\nMalování/Kreslení\nOrganizace společenských\nakcí" position={[KonickyPositionX, KonickyPositionY - 1, 0]} fontSize={0.3} />
                <TextBox position={[KonickyPositionX, KonickyPositionY + 2.5, 0]} text='Zpět' onClick={() => onAppStateChange.onAppStateChange(KdoJsemCameraPosition, AppState.KdoJsem)} />
            </OpacityValueInterpolation>
        </>
    )
}