/* eslint-disable */
import * as THREE from 'three'
import * as React from 'react'
import { Canvas, Vector3, useFrame, useThree } from '@react-three/fiber'
import TWEEN from '@tweenjs/tween.js'

import MainText from './utils/Text'
import TextBox from './utils/TextBox'
import { AppState, AppStates, GetStateTransformation, OnAppStateChange } from './AppState'
import { OpacityValueInterpolation, FadeDirection, SelectFadeDirection } from './utils/OpacityValueInterpolation'

import { KdoJsem, KdoJsemCameraPosition } from './KdoJsem'
import { Kontakt, KontaktCameraPosition } from './Kontakt'
import { Projekty, ProjektyCameraPosition } from './Projekty'
import { IntimitaErotica } from './pages/InitimitaErotica'
import { Dotaznik } from './pages/Dotaznik'
import { Vzdelani } from './pages/Vzdelani'
import { Konicky } from './pages/Konicky'


function Tween() {
  useFrame(() => {
    TWEEN.update();
  });
  return <></>;
}

export let MainPageCameraPosition: THREE.Vector3 = new THREE.Vector3(0, 0, 5);

function MainPage(onAppStateChange: OnAppStateChange) {

  function OnTextBoxClick(toPosition: THREE.Vector3, appState: AppState) {
    onAppStateChange.onAppStateChange(toPosition, appState);
  }

  return (
    <>
      <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(AppState.MainPage, onAppStateChange.appStates))} >
        <MainText text="Stranky jsou WIP" fontSize={0.3} position={[0, 3, 0]} />
        <MainText text="www.radovannetik.cz" fontSize={0.3} />
        <TextBox position={[-1.1, 0, 0]} text='Co jsem?' onClick={() => OnTextBoxClick(KdoJsemCameraPosition, AppState.KdoJsem)} />
        <TextBox position={[1.1, 0, 0]} text='Projekty' onClick={() => OnTextBoxClick(ProjektyCameraPosition, AppState.Projekty)} />
        <TextBox position={[0, -1.1, 0]} text='Kontakt' onClick={() => OnTextBoxClick(KontaktCameraPosition, AppState.Kontakt)} />
      </OpacityValueInterpolation>
    </>
  )

}

function AppController() {
  let [appStateCurrent, setAppState] = React.useState<AppStates>({ previous: AppState.MainPage, current: AppState.MainPage })

  let { camera } = useThree();

  function OnAppStateChange(toPosition: THREE.Vector3, appState: AppState) {
    let position: Vector3 | undefined = camera.position;
    let appStates: AppStates = { previous: appStateCurrent.current, current: (appState | appStateCurrent.current) };
    if (position instanceof THREE.Vector3) {
      new TWEEN.Tween(camera.position).to(toPosition, 300).easing(TWEEN.Easing.Linear.InOut)
        .onComplete((object) => { setAppState({ previous: appStateCurrent.current, current: appState }); }).start()
    }

    setAppState(appStates)
  }
  //{/* <ambientLight intensity={0.5} /> */}
  //    {/* <pointLight position={[-10, -10, -10]} /> */}

  return (
    <>
      <spotLight position={[10, 10, 10]} angle={0.45} penumbra={0.5} />
      {appStateCurrent.current & AppState.MainPage ? <MainPage appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      {appStateCurrent.current & AppState.KdoJsem ? <KdoJsem appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      {appStateCurrent.current & AppState.Kontakt ? <Kontakt appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      {appStateCurrent.current & AppState.Projekty ? <Projekty appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      {appStateCurrent.current & AppState.IntimitaErotica ? <IntimitaErotica appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      {appStateCurrent.current & AppState.Dotaznik ? <Dotaznik appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      {appStateCurrent.current & AppState.Vzdelani ? <Vzdelani appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      {appStateCurrent.current & AppState.Konicky ? <Konicky appStates={appStateCurrent} onAppStateChange={OnAppStateChange} /> : <></>}
      <Tween />
    </>
  )
}

export default function App() {
  return (
    <Canvas camera={{ position: [0, 0, 5] }} onPointerMissed={(event) => { console.log("helloThere") }}>
      <AppController />
    </Canvas>
  )
}
