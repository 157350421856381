import React from 'react'


export enum AppState {
  MainPage = 1,
  KdoJsem = 2,
  Projekty = 4,
  Kontakt = 8,
  IntimitaErotica = 16,
  Dotaznik = 32,
  Vzdelani = 64,
  Konicky = 128
}

export interface AppStates
{
  previous: AppState,
  current: AppState
}

export enum StateTransformation
{
  In,
  Stable,
  Out
}

export function GetStateTransformation(appState : AppState, appStates : AppStates)
{
    if(!(appState ^ appStates.previous) && !(appState ^ appStates.current))
    {
      return StateTransformation.Stable;
    }

    if(!(appState ^ appStates.previous) && appState ^ appStates.current)
    {
        return StateTransformation.Out
    }
    return StateTransformation.In
}

export interface OnAppStateChange{
  onAppStateChange: (a: THREE.Vector3, b: AppState) => void,
  appStates : AppStates
}
