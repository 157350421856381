import React from "react"
import * as THREE from 'three'
import { AppState, GetStateTransformation, OnAppStateChange } from "./../AppState"

import TextBox from './../utils/TextBox'
import MainText from "./../utils/Text"

import { ProjektyCameraPosition } from "../Projekty"
import { OpacityValueInterpolation, SelectFadeDirection } from "../utils/OpacityValueInterpolation"

// Center X position of this view.
export const IntimitaEroticaPositionX : number = +4.1 * 2;
export const IntimitaEroticaPositionY : number = 0;

export const IntimitaEroticaCameraPosition : THREE.Vector3 = new THREE.Vector3(IntimitaEroticaPositionX, IntimitaEroticaPositionY, 5);

const ThisAppState: AppState = AppState.IntimitaErotica;

export function IntimitaErotica(onAppStateChange : OnAppStateChange)
{
    return (
        <>
        <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(ThisAppState, onAppStateChange.appStates))} >
        <MainText text="Intimita Erotica" fontSize={0.3} position={[IntimitaEroticaPositionX, 2.5,0]}/>
        <MainText text="HTML5/Android erotic" fontSize={0.2} position={[IntimitaEroticaPositionX, 1.5,0]}/>
        <MainText text="sexual education game." fontSize={0.2} position={[IntimitaEroticaPositionX, 1,0]}/>
        <MainText text="Main purpose is to" fontSize={0.2} position={[IntimitaEroticaPositionX + 0.5, 0.5,0]}/>
        <MainText text="pursue extensive sexual" fontSize={0.2} position={[IntimitaEroticaPositionX + 0.9, 0,0]}/>
        <MainText text="communication" fontSize={0.2} position={[IntimitaEroticaPositionX + 0.7, -0.5,0]}/>
        <MainText text="between partners." fontSize={0.2} position={[IntimitaEroticaPositionX + 0.5, -1.0,0]}/>
        <TextBox position={[IntimitaEroticaPositionX - 1.1, 0, 0]} text='Zpět' onClick={() => onAppStateChange.onAppStateChange(ProjektyCameraPosition, AppState.Projekty)} />
        </OpacityValueInterpolation>
        </>
    )
}