import React from "react";
import * as THREE from 'three'

import { AppState, GetStateTransformation, OnAppStateChange } from "./AppState";
import TextBox from "./utils/TextBox";
import MainText from "./utils/Text";
import { FadeDirection, OpacityValueInterpolation, SelectFadeDirection } from "./utils/OpacityValueInterpolation";

const KontaktX : number = 0;
const KontaktY : number = -7.0;
export let KontaktCameraPosition : THREE.Vector3 = new THREE.Vector3(KontaktX, KontaktY, 5)

const ThisAppState : AppState = AppState.Kontakt;

export function Kontakt(onAppStateChange : OnAppStateChange)
{
    let [fadeDirection, setFadeDirection] = React.useState(FadeDirection.In);

    function OnClick()
    {
        onAppStateChange.onAppStateChange(new THREE.Vector3(0, 0, 5), AppState.MainPage)
        setFadeDirection(FadeDirection.Out);
    }

    return (<>
    <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(ThisAppState, onAppStateChange.appStates))} >
        <TextBox position={[KontaktX, KontaktY + 2.5, 0]} text='Zpět' onClick={() => OnClick()} />
        <MainText text="radovan.netik1@gmail.com" fontSize={0.3} position={[KontaktX,KontaktY + 1, 0]}/>
        <MainText text="792 311 734" fontSize={0.3} position={[KontaktX,KontaktY + 0.5, 0]}/>
        <MainText text="\n\nChceš interaktivní\nstránky? Napiš!" fontSize={0.3} position={[KontaktX,KontaktY -2, 0]}/>
    </OpacityValueInterpolation>
    </>)
}