import React from "react"
import * as THREE from 'three'
import { AppState, GetStateTransformation, OnAppStateChange } from "./../AppState"

import TextBox from './../utils/TextBox'
import MainText from "./../utils/Text"

import { ProjektyCameraPosition } from "../Projekty"
import { OpacityValueInterpolation, SelectFadeDirection } from "../utils/OpacityValueInterpolation"

// Center X position of this view.
export const DotaznikPositionX: number = +4.1;
export const DotaznikPositionY: number = -7.0;

export const DotaznikCameraPosition: THREE.Vector3 = new THREE.Vector3(DotaznikPositionX, DotaznikPositionY, 5);

const ThisAppState: AppState = AppState.Dotaznik;

export function Dotaznik(onAppStateChange: OnAppStateChange) {
    function OnClickLink() {
        window.open("https://dotaznik.radovannetik.cz")
    }

    return (
        <>
            <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(ThisAppState, onAppStateChange.appStates))} >
                <MainText text="Malý gamifikovaný dotazník\n pro kamarády a\ndalší existence. Vytvořeno\n v plain Three JS." position={[DotaznikPositionX, DotaznikPositionY - 1, 0]} fontSize={0.2} />
                <TextBox position={[DotaznikPositionX, DotaznikPositionY + 2.5, 0]} text='Zpět' onClick={() => onAppStateChange.onAppStateChange(ProjektyCameraPosition, AppState.Projekty)} />
                <TextBox position={[DotaznikPositionX, DotaznikPositionY - 1.5, 0]} text='Link' onClick={() => OnClickLink()} />
            </OpacityValueInterpolation>
        </>
    )
}
