import React from "react"
import * as THREE from 'three'
import { AppState, GetStateTransformation, OnAppStateChange } from "./AppState"

import TextBox from './utils/TextBox'
import MainText from "./utils/Text"

import { IntimitaEroticaCameraPosition, IntimitaErotica } from "./pages/InitimitaErotica"
import { DotaznikCameraPosition } from "./pages/Dotaznik"
import { FadeDirection, OpacityValueInterpolation, SelectFadeDirection } from "./utils/OpacityValueInterpolation"

// Center X position of this view.
export const ProjektyPositionX: number = +4.1;
export const ProjektyPositionY: number = 0;

export const ProjektyCameraPosition: THREE.Vector3 = new THREE.Vector3(ProjektyPositionX, ProjektyPositionY, 5);

const ThisAppState: AppState = AppState.Projekty;

export function Projekty(onAppStateChange: OnAppStateChange) {
    let [fadeDirection, setFadeDirection] = React.useState(FadeDirection.In);

    function OnClick(toPosition: THREE.Vector3, appState: AppState) {
        onAppStateChange.onAppStateChange(toPosition, appState);
        setFadeDirection(FadeDirection.Out);
    }
    return (
        <>
            <OpacityValueInterpolation fadeDirection={SelectFadeDirection(GetStateTransformation(ThisAppState, onAppStateChange.appStates))} >
                <MainText text="Seznam projektů" fontSize={0.3} position={[ProjektyPositionX, 2.5, 0]} />
                <TextBox position={[ProjektyPositionX + 1.1, 0, 0]} text='Intimita Erotica' onClick={() => OnClick(IntimitaEroticaCameraPosition, AppState.IntimitaErotica)} />
                <TextBox position={[ProjektyPositionX, -1.1, 0]} text='Dotazník' onClick={() => OnClick(DotaznikCameraPosition, AppState.Dotaznik)} />
                <TextBox position={[ProjektyPositionX - 1.1, 0, 0]} text='Zpět' onClick={() => OnClick(new THREE.Vector3(0, 0, 5), AppState.MainPage)} />
            </OpacityValueInterpolation>
        </>
    )
}